//fields title to top

var ua = navigator.userAgent || navigator.vendor || window.opera;
var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

if (document.documentElement.classList) {
    if (isInstagram) {
        $('.scrollable-html').removeClass('scrollable-html');
        // alert("debugging within the Instagram in-app browser");
    }
}

$('.currency_dropdown').on('click', function (e) {
    e.stopPropagation();

    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
    } else {
        $(this).addClass('active');
    }
});

$('html,body').on('click', function (e) {
    if (!$(e.target).closest('.currency_dropdown').length) {
        $('.currency_dropdown').removeClass('active');
    }
});

$('input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea')
    .each(function () {
        makeFocus($(this));
    })
    .on('input', function () {
        makeFocus($(this));
    });

//textarea height
function openTextarea(area) {
    $(area).css('height', "5px");
    $(area).css('height', (area.scrollHeight) + "px");
}

$('.form-group textarea').on('input', function () {
    openTextarea(this);
});
if ($('.letter-content').length) {
    $('html, body').removeClass('scrollable-html');
}

var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


$(window)
    .on('load', function () {
        // imageBlogEach()
        $('.form-group textarea').each(function () {
            openTextarea(this);
        });
        if ($('.autoplay-load').length) {
            $('.autoplay-load').each(function () {
                var id = $(this).attr('id');
                var _this = this;
                this.player = new YT.Player(id, {
                    events: {
                        'onReady': function (event) {
                            if ($(_this).hasClass("autoplay-load-first")) {
                                event.target.playVideo();
                                $(_this).addClass('play').closest('.home-header-video').removeClass('image-show')
                                $('.home-header-slider').slick('slickPause');
                            }
                        },

                        'onStateChange': function (event) {
                            console.log(event.data)

                            if (event.data == 1) {
                                $(_this).closest('.home-header-video').removeClass('image-show')
                            }
                            else if (event.data == 0) {
                                $('.home-header-slider').slick('slickNext');

                                $(_this).closest('.home-header-video').removeClass('image-show')
                            }
                            else {
                                $(_this).closest('.home-header-video').addClass('image-show')
                            }
                        }
                    }
                });
            })
        }

    })
    .on('load resize', function () {//square min height
        if ($('.home-header-video').length) {
            if ($(window).width() > 768) {
                $('.home-header-video').height($('.home-header-video').width() / 16 * 9);
            }
        }
        $('.square, .product-img-rectangle').each(function () {

            if ($(window).width() > 1024 || !$(this).hasClass('small-square')) {
                $(this).css('min-height', $(this).width());
            }

            if ($(window).width() < 768) {
                $(this).find('.gallery-list .gallery-list-item').css('min-height', $(this).width());
            }
        });

        // fix footer padding if there are no btns stick to bottom
        if ($(window).width() < 768) {
            var filterBtn = $('body').find('.filter-cover_title').length,
                addToCartBtn = $('.mobile-product-buttons').length;
            if (!filterBtn && !addToCartBtn) {
                $('.footer').css('padding-bottom', '20px');
            }
        }

        //help popup only for mobile/table and (hover block for desktop)

        if ($('.need-help-btn').length) {
            if ($(window).width() < 768) {
                $('.need-help-btn').attr('data-popup', 'help-popup');
            } else {
                $('.need-help-btn').removeAttr('data-popup');
            }
        }
    });


function makeFocus($el) {
    if ($el.val().length) {
        $el.addClass('focus');
    } else {
        $el.removeClass('focus');
    }
}

function overflowHidden() {
    $('body').css('overflow', 'hidden');
}

function overflowAuto() {
    $('body').css('overflow', 'auto');
    $(document.body).css('position', '');
}

var $menuIcon = $('#menu-icon');
var $header = $('#header');
var $headerFirst = $header.find('.header-first');

function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
//
// if ($('html').attr('lang') == 'ru') {
//     if (!getCookie('firstru')) {
//         $('.first-popup-' + $('html').attr('lang')).show();
//         document.cookie = "firstru=1";
//         $('#first-popup').fadeIn();
//     }
// }
//
// else if ($('html').attr('lang') == 'en') {
//     if (!getCookie('firsten')) {
//         $('.first-popup-' + $('html').attr('lang')).show();
//         document.cookie = "firsten=1";
//         $('#first-popup').fadeIn();
//     }
// }
var scrollHearer = 0;
//
// if (getCookie('header') == 1) {
//     $menuIcon.css('transition', 'none').addClass('open').attr('style', '');
//     $header.css('transition', 'none').addClass('open').attr('style', '');
//     $headerFirst.css({'display': 'block'})
//     $('.header-height').css('transition', 'none').addClass('open').attr('style', '');
// }

//menu

$(window)
    .on('load resize', function () {


        $menuIcon.on('click', function () {
            scrollHearer = scrollY
            $('body').addClass('paused');
            setTimeout(function () {
                $('body').removeClass('paused')
            }, 1000)
            $(this).toggleClass('open');
            $(this).closest('.header-height').toggleClass('open');

            // if ($(this).hasClass('open')) {
            //     $.get('/api/change-menu/?value=0');
            // }
            // else {
            //     $.get('/api/change-menu/?value=1');
            // }
            if ($(this).hasClass('open') && $(window).width() <= 1024) {
                overflowHidden();

            } else {
                overflowAuto();

            }
            $('.menu_mob').addClass('active');
        });

        if ($(this).width() > 1024) {
            if (!$header.hasClass('desktop-header')) {
                $header.removeClass('mobile-header open').addClass('desktop-header');
                $menuIcon.off('click', openMenu);
                $menuIcon.on('click', openFirstHeader);

                if ($header.closest('.header-first').hasClass('open')) {
                    $headerFirst.stop().show();
                    $menuIcon.addClass('open');
                }
            }

            //activate hover effect for submenu


        } else {
            if (!$header.hasClass('mobile-header')) {

                $header.removeClass('desktop-header').addClass('mobile-header');
                $menuIcon.removeClass('open').off('click', openFirstHeader).on('click', openMenu);
                $headerFirst.removeAttr('style');
            }

        }
    })
    .on('scroll', function () {
        $('.v-dropdown').removeClass('open');
        if (innerWidth > 1200) {
            // $('.catalog-img_hover:not(.lazy_done), .catalog-img:not(.lazy_done)').each(function () {
            //     if ($(this).offset().top < scrollY + innerHeight) {
            //         var bg = $(this).data('image');
            //         $(this).addClass('lazy_done');
            //         if (bg) {
            //             $(this).css('background-image', 'url("' + bg + '")');
            //         }
            //     }
            // });
            if (scrollY > 50) {
                if (scrollHearer > scrollY + 100 || scrollHearer < scrollY - 100) {

                    if ($('.menu-icon.open').length) {
                        $('.menu-icon.open').click()
                        scrollHearer = scrollY;
                    }
                }
                if ($('.price-mobile-show').length) {
                    if ($('body').scrollTop() > $('.price-mobile-show').offset().top) {
                        $('.catalog-price-mobile').css('opacity', '1')
                    } else {
                        $('.catalog-price-mobile').css('opacity', '0')
                    }
                }
            }
            else {
                if (!$('.menu-icon.open').length) {
                    $('.menu-icon').click()
                    scrollHearer = scrollY;
                }
            }
        }
    });
$('html,body').on('scroll', function () {
    if ($('.price-mobile-show').length) {
        if ($('.price-mobile-show').offset().top < 0) {
            $('.catalog-price-mobile').css('opacity', '1')
        } else {
            $('.catalog-price-mobile').css('opacity', '0')
        }
    }
    if ($('.image-dot.active').length) {
        $('.image-dot.active').removeClass('animation-open').addClass('animation-close').removeClass('active')
    }
});

function openFirstHeader() {
    $headerFirst.stop().slideToggle(400);
}

function openMenu() {
    $('.header').toggleClass('open');
}

$('.header-menu-item-cover').click(function () {
    $(this).toggleClass('open');
});

$('.header-menu-item-cover a').click(function (e) {
    e.stopPropagation();
});

$(document).on('click', function () {
    $('.header-second').removeClass('search-open');
});

$('.desktop-search').on('click', function (e) {
    e.stopPropagation();
});

$('.desktop-search-open').on('click', function (e) {
    $(this).closest('.header-second').addClass('search-open').focus();
    $(this).siblings('input').focus();
});

//dropdowns
$(window).click(function (e) {
    if (!$(e.target).closest('.dropdown').length) {
        $('.dropdown').removeClass('open');
        if ($(window).width() < 768) {
            $('.dropdown .dropdown-block').stop().slideUp();
        } else {
            $('.dropdown .dropdown-block').stop().fadeOut(200);
        }
    }
});


$('.filter-close').on('click', function () {
    var $opener = $(this).closest('.filter-cover, .filter-brand-cover');
    $opener.toggleClass('open');

    if ($opener.hasClass('open') && $(window).width() <= 1024) {
        overflowHidden()
    } else {
        overflowAuto()
    }
})
$('.dropdown .dropdown-head').on('click', function () {
    if ($(this).parent().hasClass('open')) {
        if ($(window).width() < 768) {
            $('.dropdown .dropdown-block').stop().slideUp();
        } else {
            $('.dropdown .dropdown-block').stop().fadeOut(200);
        }
        $('.dropdown').removeClass('open');
    } else {
        if ($(window).width() < 768) {
            $('.dropdown .dropdown-block').stop().slideUp();
        }
        else {
            $('.dropdown .dropdown-block').stop().fadeOut(200);
        }
        $('.dropdown').removeClass('open');
        if ($(window).width() < 768) {
            $(this).parent().find('.dropdown-block').stop().slideDown();
        }
        else {
            $(this).parent().find('.dropdown-block').stop().fadeIn(200);
        }
        $(this).parent().addClass('open');
    }
});

$('.nav-mobile-black-cover').click(function (e) {
    if ($(window).width() < 768) {
        $(this).toggleClass('open');
        $('body').toggleClass('cover');
    }
});

//v-dropdown
var vDropdownMouse = true;

var vDropdownOpen = function (e) {
    if (TimeCard) {
        clearTimeout(TimeCard);
    }
    if (innerWidth > 1200) {
        $(e.target).closest('.v-dropdown').addClass('open');
        if ($('.cart-dropdown-inner').find('.cart-item').length > 0) {
            var i = $('.cart-dropdown-inner').find('.cart-item').length;
            $(".cart-dropdown-inner > .cart-item").hide().slice(i - 2, i).show();
        }
    }
    else {
        $(e.target).closest('.v-dropdown').toggleClass('open');
        if ($('.cart-dropdown-inner').find('.cart-item').length > 0) {
            var i = $('.cart-dropdown-inner').find('.cart-item').length;
            $(".cart-dropdown-inner > .cart-item").hide().slice(i - 2, i).show();
        }
    }
};

var vDropdownClose = function (e) {
    if (innerWidth > 1200) {
        $(e.target).closest('.v-dropdown').removeClass('open');
    }
};

// var vDropdownTotalClose = function(e) {
//     if (!($(e.target).hasClass('v-dropdown') || $(e.target).hasClass('v-dropdown-opener'))) {
//         $('.v-dropdown').removeClass('open');
//     }
// }

$('.v-dropdown-opener')
    .on('mouseenter', vDropdownOpen)
    .on('click', function () {
        if (innerWidth > 1200) {
            $(this).closest('.v-dropdown').removeClass('open');
        }
    });

$('.v-dropdown').on('mouseleave', vDropdownClose);
$('.cart-dropdown').mousemove(function () {
    if (TimeCard) {
        clearTimeout(TimeCard);
    }
});
$(window).on('load resize', function () {

    if ($(this).width() <= 1024 && vDropdownMouse) {
        $('.v-dropdown-opener').off('mouseenter', vDropdownOpen);
        $('.v-dropdown').off('mouseleave', vDropdownClose);

        $('.v-dropdown-opener').on('click', vDropdownOpen);
        // $(window).on('click', vDropdownTotalClose);

        vDropdownMouse = false;
    } else if ($(this).width() > 1024 && !vDropdownMouse) {
        $('.v-dropdown-opener').off('click', vDropdownOpen);
        // $(window).off('click', vDropdownTotalClose);

        $('.v-dropdown-opener').on('mouseenter', vDropdownOpen);
        $('.v-dropdown').on('mouseleave', vDropdownClose);

        vDropdownMouse = true;
    }
});


function tabsHeight() {
    setTimeout(function () {
        if ($('#size-popup #measure').innerHeight() != 0) {
            $('#size-popup #table').height($('#size-popup #measure').innerHeight())
            if ($('#size-popup  .popup-body').height() < $('#size-popup #measure').innerHeight()) {
                $('#size-popup #table').height($('#size-popup  .popup-body').height());
            }

        }
        else {
            tabsHeight()
        }
    }, 10)
}
//popup
$('[data-popup]').click(function () {
    if ($(this).data('popup') == 'signin-popup') {
        $(this).closest('.signin-popup').fadeOut();
        var click = $(this).data('click');
        console.log(click)
        $('.' + click).click();
    }
    else if ($(this).data('popup') == 'size-popup') {
        if (innerWidth > 720) {
            tabsHeight();
        }
    }
    else if ($(this).data('popup') == 'password-popup') {
        $(this).closest('.signin-popup').fadeOut();
    }
    else if ($(this).data('popup') == 'fast-checkout') {
        dataLayer.push({'event': 'quick_order'});
        ga('send', 'event', 'quick', 'order');
        var url = $('.fast-checkout-content').data('url');
        $('.fast-checkout-content').load(url + ' #order-iframe');

        // update sum
        var itemPrice = $('.product-about').find('.catalog-price').first().text(),
            itemValut = $('.product-about').find('.price-sym').first().text(),
            cartSum = $('.cart-total-sum').first().text(),
            cartValut = $('.currency_dropdown span').text();

        if (!itemPrice) {
            itemPrice = $('.price-total').first().text()
        }

        $('#fast-checkout-content').find('.cart-total-sum').text(itemPrice);
        $('#fast-checkout-content').find('.price-sym').text(cartValut);

        if ($('input[name="set_id"]').length) {
            $('.fast-product_id').val($('input[name="set_id"]').val()).attr('name', 'set_id');
        }
        else {
            $('.fast-product_id').val($('input[name="product"]').val());
            $('.fast-product_size').val($('.checkbox-icon').find(':checked').siblings('input[name="warehouse_id"]').val());
        }
        // if ($(this).siblings('.cart-btn').length) {
        //     // show item price if ckclicked on the btn near cart btn
        //     $('#fast-checkout-content').find('.cart-total-sum').text(itemPrice);
        //     $('#fast-checkout-content').find('.price-sym').text(itemValut);
        // } else {
        //     // show cart sum if clicked on the btn inside cart popup
        //
        // }
    } else if ($(this).data('popup') === 'fast-checkout-cart') {

        // update sum
        var cartSum = $('.cart-total-sum').first().text(),
            cartValut = $('.price-sym').first().text();

        // $('.fast-checkout-form').attr()

        $('#fast-checkout-cart-content').find('.cart-total-sum').text(cartSum);
        $('#fast-checkout-cart-content').find('.price-sym').text(cartValut);


    }

    $('#fast-checkout-cart').find('button[type="submit"]').on('click', function (e) {
        e.preventDefault();

        var $form = $(this).closest('form'),
            url = $('#fast-checkout-cart').data('url');

        var success = !requiredFieldsError($form);

        if (success) {

            var visibleData = $form.find(':input:not([type=hidden])').serialize();

            $.post(url, visibleData, function (result) {
                console.log(result)
                console.log(result.status)
                if (result.status === 'done') {
                    // window.location.href = "/order/success-fast";
                }
            });
        }
    });

    var countSlide = $(this).closest('.row').find('.gallery-list-item.active:visible').index();

    var _this = $(this);
    $('#' + $(this).attr('data-popup')).each(function () {
        $(this).prepend('<div class="mask-popup"></div>');
        $('body').css('overflow', 'hidden');
        if ($(this).hasClass('zoom-popup') && !$(this).find('.zoom-slider.slick-initialized').length) {

            var $zoomSlider = $('#zoom-slider');

            $(this).find('.zoom-count').text($zoomSlider.find('.zoom-img').length);

            $zoomSlider.slick({fade: true});

            $zoomSlider.on('beforeChange', function (slick, currentSlide) {
                $(this).parent().siblings('.zoom-content').find('.zoom-index').addClass('rotate').delay(500).queue(function () {
                    $(this).text(currentSlide.currentSlide + 1).removeClass('rotate').dequeue();
                })
            });
        } else if ($(this).hasClass('zoom-popup-product') && !$(this).find('.zoom-slider.slick-initialized').length) {

            var $zoomSlider = $(this).find('#zoom-slider-product');

            $(this).find('.zoom-count').text($zoomSlider.find('.zoom-img').length);

            $zoomSlider.slick({fade: true});

            $zoomSlider.on('afterChange', function (slick, currentSlide) {
                $(this).parent().siblings('.zoom-content').find('.zoom-index').text(currentSlide.currentSlide + 1);
            });
        }

        setTimeout(function () {
            $('.slick-slider:not(.set-slider)').slick('slickGoTo', countSlide, true);
        }, 1)

    }).fadeIn(400, function () {

    });
});


// lazy load for gallery photo
$('.zoom-img').on('click', function () {

    var url = $(this).data('big-url');
    var type = $(this).closest('.row').siblings('input[name="type"]').val();

    var data = new FormData();
    data.append('photo', url);
    data.append('type', type);

    $.ajax({
        type: "POST",
        url: '/api/get-photo/',
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        success: function (response) {
            var response = $.parseJSON(response);

            if (response.status == 'done') {
                var img = new Image;
                img.src = response.result;
                img.onload = function () {
                    $('#zoom').css("background-image", "url('" + response.result + "')");
                    $('#zoom').fadeIn()
                };

            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    });

})

$(document).on('click', '.popup-close, .mask-popup', function () {
    $(this).closest('.popup-overlay, .zoom-popup, .signin-popup, .zoom-popup-product').fadeOut();
    clearInputErrors($(this).parent().siblings('form'))
    clearInputErrors($(this).parent().find('form'))
    $('body').css('overflow', '');
});

function clearInputErrors($form) {
    $form.find('.form-group.has-error').removeClass('has-error');
}

//table dropdowns
$('.dropdown-table-title').click(function () {
    $(this).parent().toggleClass('open');
});

$('.block-right_content').each(function () {
    var height = $(window).height() - $(this).closest('.block-right').find('.block-right_title').height() - $(this).closest('.block-right').find('.block-right_footer').height() - 72;
    if (innerWidth > 1200) {
        $(this).scrollable({
            height: height,
            width: 280,
            showButtons: false,
            animate: true,
            mousewheelSpeed: 1
        });
    }
    else {
        $(this).height(height).css('overflow-y', 'scroll')

    }
});

function feedbackClose() {
    $('body').removeClass('feedback-open');
}

$('.feedback_close').click(function () {
    feedbackClose();
});

$('.feedback-open-btn').click(function () {
    $('#help-popup').fadeOut();
    $('body').addClass('feedback-open');
});

$('.cart_close').click(function () {
    $('body').removeClass('cart-open');
});

$('.cart-open-btn').click(function () {
    $('body').addClass('cart-open');
});

//add to wishlist

$(document).on('click', '.catalog-wish', function (e) {
    e.stopPropagation();
    e.preventDefault();

    var $this = $(this);
    var data = new FormData();
    var id;

    if ($this.hasClass('active')) {
        id = +$this.attr('data-wishlist-id');

        if (id) {
            data.append('wish_list_id', id);

            $.ajax({
                type: "POST",
                url: '/api/del-from-wishlist/',
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    var response = $.parseJSON(response);

                    if (response.status == 'done') {
                        $this.removeClass('active').removeAttr('data-wishlist-id');
                        console.log($this.parent());
                        if ($this.parent().parent().hasClass('wishlist-container')) {
                            $this.parent().remove();
                        }
                        $('#wishlist-count').text(+$('#wishlist-count').text() - 1);
                    } else if (response.status == 'error') {
                        if (response.result == 'unauthorized') {

                        }
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }
    } else {
        id = +$this.attr('data-set-id');
        var isset = +$this.attr('data-isset');

        if (id) {
            data.append('prod_id', id);

            if (isset) data.append('is_set', 1);

            $.ajax({
                type: "POST",
                url: '/api/add-to-wishlist/',
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    var response = $.parseJSON(response);

                    if (response.status == 'done') {
                        $this.addClass('active').attr('data-wishlist-id', response.result);
                        $('#wishlist-count').text(+$('#wishlist-count').text() + 1);
                    } else if (response.status == 'error') {
                        if (response.result == 'unauthorized') {

                        }
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }


    }
});

// open nedded tab when clicking on wishlist icon

var hash = document.location.hash;
var prefix = "tab_";

if (hash) {
    $('.nav a[href="' + hash.replace(prefix, "") + '"]').tab('show');
}

// Change hash for page-reload
$('.nav a').on('shown', function (e) {
    window.location.hash = e.target.hash.replace("#", "#" + prefix);
});

//subscribe
$('.subscribe-btn').on('click', function () {
    var $email = $(this).siblings("input.mail");
    if ($email.val()) {

        if (!validateEmail($email.val())) {

            $email.parent().addClass('has-error');
            // error = true;
        }
        else {
            $email.parent().removeClass('has-error');

            var emailVal = $email.val();

            var data = new FormData();
            data.append('email', emailVal);

            $.ajax({
                type: "POST",
                url: '/api/add-subscribers/',
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    var response = $.parseJSON(response);
                    $email.val('').removeClass('focus');

                    if (response.status == 'done') {
                        $('#sps-popup').fadeIn()
                        setTimeout(function () {
                            $('#sps-popup').fadeOut()
                        }, 2000)

                    } else if (response.status == 'error') {
                        $('#subscribe-err-popup').fadeIn()
                        setTimeout(function () {
                            $('#subscribe-err-popup').fadeOut();
                        }, 2000)
                    }
                },

                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }
    } else {
        $email.focus();
    }
});

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[­[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//
// var imgCanvas = false;
// var imgContext = false;
// var imgBlock = false
// var elemTest;
// $(document).on('mouseenter', '.gallery-image', function () {
//     imgCanvas = document.createElement('canvas');
//     imgContext = imgCanvas.getContext('2d');
//     imgBlock = $(this)
//     $('.product-image_cover').closest('.row').after(imgCanvas)
//     var url = $(this).find('.gallery-image-inside').css('background-image');
//     url = url.replace('url(', '').replace(')', '').replace(/\"/gi, "");
//     var img = new Image;
//     img.src = url;
//
//     var w = $(this).innerWidth();
//     var h = $(this).innerHeight();
//
//     imgCanvas.width = w;
//     imgCanvas.height = h;
//     var imgWidth = w;
//     var imgHeight = h;
//     img.onload = function () {
//         if (img.height / img.width > h / w) {
//             imgWidth = h / img.height * img.width
//         }
//         else {
//             imgHeight = w / img.width * img.height
//         }
//         imgContext.drawImage(img, (w - imgWidth) / 2, 0, imgWidth, imgHeight);
//     }
// });
// $(document).on('mouseleave', '.gallery-image', function () {
//
//     imgCanvas = false;
//     imgContext = false;
//     imgBlock = false;
//
// });
//
//
// $(document).mousemove(function (e) {
//     if (imgCanvas) {
//         var x = e.pageX - imgBlock.offset().left, y = e.pageY - imgBlock.offset().top;
//         var imageData = imgContext.getImageData(x + 12, y + 12, 1, 1);
//         var pixel = imageData.data;
//
//         if ((pixel[0] + pixel[1] + pixel[2]) / 3 < 128) {
//             $('.gallery-image').addClass('light')
//         }
//         else {
//             $('.gallery-image').removeClass('light')
//         }
//     }
// });


var btnOkfunction = function () {
};


$(document).on('click', '.btn_cancel', function () {
    $('.confirm').fadeOut();
    btnOkfunction = function () {
    }
});
$(document).on('click', '.btn_ok', function () {
    $('.confirm').fadeOut();
    btnOkfunction();
    btnOkfunction = function () {
    }
});


$(document).on('click', '.menu_mob_close, .menu_mob_mask', function () {
    $('.menu_mob').removeClass('active');
    $('body').css('overflow', 'auto');
    $('#menu-icon').removeClass('open')
});
$(document).on('click', '.search-mob_close', function () {
    $('.search-mob').removeClass('active');
});
$(document).on('click', '.search-mob-open', function () {
    $('.search-mob').addClass('active');
});


$(document).on('touchstart', '.catalog-one', function () {
    $('.hover').removeClass('hover');
    $(this).addClass('hover')
});

var requiredFieldsError = function ($form) {

    var error = false;

    $form.find('.form-group.required:visible').each(function () {

        var $input = $(this).find('.form-input'),
            $selectOption = $(this).find('select option');

        if ($input.val() == '') {
            $input.parent().addClass('has-error');
        }
        else {
            $input.parent().removeClass('has-error');
        }

        if ($(this).find("input.mail").length) {
            var email = $(this).find("input.mail");

            if (!validateEmail(email.val())) {

                email.parent().addClass('has-error');
            }
            else {
                email.parent().removeClass('has-error');
            }
        }

        if ($selectOption.length) {

            if ($selectOption.val() == '' && $selectOption.closest('.form-group').is(":visible")) {
                $selectOption.closest('.form-group').addClass('has-error')
                    .find('.custom-select').addClass('has-error');

            } else if ($selectOption.val() != '' && $selectOption.closest('.form-group').is(":visible")) {
                $selectOption.closest('.form-group').removeClass('has-error')
                    .find('.custom-select').removeClass('has-error');
            }
        }
    });

    if ($form.find('.has-error').length) {
        error = true
    }

    return error
}

function callAjax(url, data, successCallback) {
    $.ajax({
        method: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        success: successCallback,
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    });
}

function clearFormInputVals($form) {
    $form.find('input:visible').val('').removeClass('focus');
}

$(document).on('submit', 'form.feedback-form', function (e) {
    e.preventDefault();

    var data = $(this).serialize();
    var url = $(this).data('action'),
        _this = $(this);

    var error = requiredFieldsError($(this));

    if (!error) {

        var $btnTextField = _this.find('button[type="submit"] span')

        $btnTextField.data('text', $btnTextField.text());
        var btnText = $btnTextField.data('text');

        var textTrue = true;
        var timeDot = setInterval(function () {
            if (textTrue) {
                $btnTextField.text('.');
            }
            setTimeout(function () {
                if (textTrue) {
                    $btnTextField.text('. .')
                }
                setTimeout(function () {
                    if (textTrue) {
                        $btnTextField.text('. . .')
                    }
                }, 200)
            }, 200)

        }, 600);

        callAjax(url, data, onSuccess)

        function onSuccess(response) {

            if (response.status == 'done') {

                if (_this.parent('.popup').length) {

                    setTimeout(function () {
                        textTrue = false
                        clearTimeout(timeDot);
                        $btnTextField.html('<i class="material-icons">&#xE876;</i>');

                        setTimeout(function () {
                            $btnTextField.text(btnText);
                            clearFormInputVals(_this);
                            _this.closest('.popup-overlay').fadeOut();
                            overflowAuto()
                        }, 500)
                    }, 1000)


                } else if (_this.parent('#feedback-right').length) {

                    setTimeout(function () {
                        textTrue = false
                        clearTimeout(timeDot);
                        $btnTextField.html('<i class="material-icons">&#xE876;</i>');

                        setTimeout(function () {
                            $btnTextField.text(btnText);
                            clearFormInputVals(_this)
                            feedbackClose()
                            overflowAuto()
                        }, 500)
                    }, 1000)
                }

            } else if (response.status == 'error') {

            }
        }
    }
});

$('.form-group-password').click(function () {

    var el = $(this).siblings('input');
    if (el.attr('type') == 'password') {
        $(this).addClass('active').siblings('input').attr('type', 'text');
        $(this).find('.material-icons').html('&#xE417;')
    } else {
        $(this).removeClass('active').siblings('input').attr('type', 'password');
        $(this).find('.material-icons').html('&#xE8F5;')
    }
})


jQuery.expr[':'].contains = function (a, i, m) {
    return jQuery(a).text().toUpperCase()
            .indexOf(m[3].toUpperCase()) >= 0;
};


$('.dropdown-search-form input').keyup(function () {
    if ($(this).val() == '') {
        $('.designer-dropdown-width .checkbox').show()
        $('.designer-dropdown-width .row').show()
    }
    else {
        $('.designer-dropdown-width .checkbox').hide()
        $('.designer-dropdown-width .row').hide()
        $('.designer-dropdown-width .checkbox:contains(' + $(this).val() + ')').show().closest('.row').show()
    }
})

$(document).on('submit', '#fast-checkout form.fast-checkout-form', function (e) {
    var success = !requiredFieldsError($(this));
    if (success) {
        dataLayer.push({'event': 'quick_order_successs'});
    }
    return success
});


$('.user-form').on('beforeValidate', function (event) {

    // validation for required inputs
    $(this).find('.form-group.required').each(function () {

        var input = $(this).find("input");

        if (input.val() == '') {
            input.parent().addClass('has-error');
        }
        else {
            input.parent().removeClass('has-error');
        }

        if ($(this).find("input.mail").length) {
            var email = $(this).find("input.mail");

            if (!validateEmail(email.val())) {

                email.parent().addClass('has-error');
                // error = true;
            }
            else {
                email.parent().removeClass('has-error');
            }
        }
    });

    if ($(this).find('.has-error').length) {

        return false
    }
});

 
$('.instagram-list').slick({
    centerMode: true, slidesToShow: 2,
    dots: false,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,

});


$('#w0.order-form').on('afterValidate', function () {
    dataLayer.push({'event': 'ukr_order_success'});
});
$('#w1.order-form').on('afterValidate', function () {
    dataLayer.push({'event': 'international_order_success'});
});

var mainMenuToggle = (function () {

    var $menuLinksWithSubmenu = $('.has-submenu'),
        $submenuWrapper = $('.header-menu__submenu'),
        $menuLinksWithSubmenuMobile = $('.second__wrap'),
        touched = false;

    function sidebarMouseEnter(e) {
        if ( $(e.target).closest($menuLinksWithSubmenu).length ){
            $(e.target).closest($menuLinksWithSubmenu).find($submenuWrapper).addClass('active');
        }
    }

    function sidebarMouseLeave(e) {
        $(e.target).closest('.header').find($submenuWrapper).removeClass('active');
    }

    var event = function () {

        // 1199px last breakpoint where mobile menu is active
        if ($(window).width() < 1200) {
            touched = true;
        }
        $menuLinksWithSubmenu.on('mouseover', function (e) {
            if (!touched) {
                sidebarMouseEnter(e);
            }
        });
        $menuLinksWithSubmenu.on('mouseleave', function(e){
            if (!touched) {
                sidebarMouseLeave(e);
            }
        });

        $('.header-menu-cover').on('touchstart', function () {
            touched = true;
        });


        $menuLinksWithSubmenuMobile.on('click', function(e) {


            //if not menu link clicked directly
            if ( !$(e.target).hasClass('second_a') ) {
                var $parent = $(this).closest($menuLinksWithSubmenu),
                    _this = $(this);

                if ( $parent.hasClass('opened') ) {
                    $parent.removeClass('opened');
                    $(this).siblings($submenuWrapper).slideUp();

                } else {
                    $submenuWrapper.not(_this.siblings($submenuWrapper)).slideUp();
                    $menuLinksWithSubmenu.removeClass('opened');
                    $parent.addClass('opened');
                    // console.log(_this.siblings($submenuWrapper).parent().offset().top)

                    _this.siblings($submenuWrapper).slideDown(400, function(){
                        _this.siblings($submenuWrapper).parent()[0].scrollIntoView({behavior: "smooth"});
                    });
                }
            }
        })

    };

    var init = function () {
        event();
    }

    init();

})();