/*
    inputCheck plugin
    non-chainable, return true if check is successful
    author Hanna Viznyuk <hannawavy@gmail.com>
*/
'use strict';

(function($) {
    //regex checks
    var emailReg = /^[A-Za-z0-9][?\w\.\-]*@[A-Za-z\-\.]*\.[A-Za-z]*$/;
    var passwordNumberReg  = /[0-9]/;
    var passwordLowerReg  = /[a-z]/;
    var passwordUpperReg = /[A-Z]/;
    var phoneReg = /^\+{0,1}[\s0-9\-\(\)]*$/;
    var dateReg = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
    var videoYoutubeReg = /[y][o][u][t][u][\.]?[b][e]/;
    var videoReg = /[0-9a-zA-Z\-\_]*$/;
    var floatReg = /^[0-9]*[\.\,]?[0-9]*$/;
    var intReg = /^[-]?[0-9]*$/;
    var linkReg = /^http[s]?\:\/\//;
    var errorText;

    var methods = {
        checkAllFields: function(lang, callback) {
            //data-types
            var requiredCheckboxes = this.find('input[data-required="required"][type="checkbox"]');
            var emails = this.find('input[data-input-type="email"]');
            var phones = this.find('input[data-input-type="phone"]');
            var passwords = this.find('input[data-input-type="password"]');
            var dates = this.find('input[data-input-type="date"]');
            var videos = this.find('input[data-input-type="video"]');
            var texts = this.find('input[data-required="required"]:not([data-input-type]), textarea[data-required="required"]');
            var captcha = this.find('[name="recaptcha_response_field"]');
            var requiredSelects = this.find('select[data-required="required"]');
            var requiredFiles = this.find('input[data-required="required"][type="file"], input[data-required="required"][data-input-type="file"]');
            var floats = this.find('input[data-input-type^="float"]');
            var ints = this.find('input[data-input-type^="int"]');
            var links = this.find('input[data-input-type="link"]');

            var _this = this;

            $.getJSON("static/js/separate-js/input.check.lang.json", function(data) {
                if (lang !== undefined) {
                    var errorTextLang = data[lang];
                } else {
                    var errorTextLang = data['en'];
                }

                //result
                var t = true;

                _this.find('#recaptcha_widget_div, input, textarea, select').inputCheck('errRemove');

                _this.find('input[type="checkbox"]:not(:checked)').removeAttr('checked');
                _this.find('input[type="checkbox"]:checked').attr('checked','checked');

                if (passwords.length) {
                    passwords.each(function() {
                        if ($(this).val().length) {
                            if ($(this).val().length < 6) {
                                t = $(this).inputCheck("makeError", errorTextLang.noEnoughCharacters) && t;
                            }
                            if ($(this).attr("data-input-checking") == 'strong') {
                                t = $(this).inputCheck("checkField", passwordNumberReg, errorTextLang.noPassNumber) && t;
                                t = $(this).inputCheck("checkField", passwordLowerReg, errorTextLang.noPassLowCase) && t;
                                t = $(this).inputCheck("checkField", passwordUpperReg, errorTextLang.noPassUpCase) && t;
                            }
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (texts.length) {
                    texts.each(function() {
                        if (!$(this).val().length) {
                            t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                        }
                    });
                }

                if (captcha.length) {
                    captcha.each(function() {
                        if (!$(this).val().length) {
                            t = $(this).closest('#recaptcha_widget_div').inputCheck("makeError", errorTextLang.emptyField) && t;
                        }
                    });
                }

                if (emails.length) {
                    emails.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", emailReg, errorTextLang.noCorrectEmail) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (phones.length) {
                    phones.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", phoneReg, errorTextLang.wrongPhone) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (floats.length) {
                    floats.each(function() {
                        if ($(this).val().length) {
                            $(this).val($(this).val().replace(",","."));
                            if ($(this).data("value-max") !== undefined) {
                                if ($(this).val() > +$(this).data("value-max")) {
                                    t = $(this).inputCheck("makeError", errorTextLang.biggerFloat + $(this).data("value-max")) && t;
                                }
                            }
                            if ($(this).data("value-min") !== undefined) {
                                if ($(this).val() < +$(this).data("value-min")) {
                                    t = $(this).inputCheck("makeError", errorTextLang.smallerFloat + $(this).data("value-min")) && t;
                                }
                            }
                            t = $(this).inputCheck("checkField", floatReg, errorTextLang.wrongFloat) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (ints.length) {
                    ints.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", intReg, errorTextLang.wrongFloat) && t;

                            if (t) {
                                if ($(this).data("value-max") !== undefined) {
                                    if ($(this).val() > +$(this).data("value-max")) {
                                        t = $(this).inputCheck("makeError", errorTextLang.biggerFloat + $(this).data("value-max")) && t;
                                    }
                                }
                                if ($(this).data("value-min") !== undefined) {
                                    if ($(this).val() < +$(this).data("value-min")) {
                                        t = $(this).inputCheck("makeError", errorTextLang.smallerFloat + $(this).data("value-min")) && t;
                                    }
                                }
                            }
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (dates.length) {
                    dates.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", dateReg, errorTextLang.wrongDate) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (videos.length) {
                    videos.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", videoYoutubeReg, errorTextLang.videoNotExist) && t;
                            t = $(this).inputCheck("checkField", videoReg, errorTextLang.videoNotExist) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (links.length) {
                    links.each(function() {
                        if ($(this).val().length) {
                            t = $(this).inputCheck("checkField", linkReg, errorTextLang.linkNotValid) && t;
                        } else {
                            if ($(this).data("required") == "required") {
                                t = $(this).inputCheck("makeError", errorTextLang.emptyField) && t;
                            }
                        }
                    });
                }

                if (requiredCheckboxes.length) {
                    requiredCheckboxes.each(function() {
                        if (!$(this).attr('checked')) {
                            t = $(this).inputCheck("makeError", errorTextLang.noAgreement) && t;
                        }
                    })
                }

                if (requiredSelects.length) {
                    requiredSelects.each(function() {
                        if (!$(this).val().length) {
                            t = $(this).inputCheck("makeError", errorTextLang.requiredSelect) && t;
                        }
                    });
                }

                if (requiredFiles.length) {
                    requiredFiles.each(function () {
                        if (!$(this).val().length) {
                            t = $(this).inputCheck("makeError", errorTextLang.requiredFile) && t;
                        }
                    });
                }

                callback(t);
            });
            // .error(function(xhr, ajaxOptions, thrownError) {
            //     console.log("error loading JSON: " + xhr.status + " " + thrownError);
            // });
        },

        //remove error class. chainable
        errRemove: function() {
            return this.each(function() {
                $(this).closest('.inp-block, .form-group').removeClass('has-error');
                var errBlock = $(this).closest('.inp-block, .form-group').find('.inp-err').first();
                if (errBlock.text()) { errBlock.text(''); }
            });
        },

        //full error making. bool
        makeError: function(text) {
            this.closest('.inp-block, .form-group').addClass('has-error');

            var errBlock = this.closest('.inp-block, .form-group').find('.inp-err').first();
            if (errBlock.text()) { errBlock.append('<br>'); }
            errBlock.append(text);
            return false;
        },

        //check field for some value. bool
        checkField: function(expr, err) {
            if (!expr.test(this.val())) {
                this.inputCheck("makeError", err);
                return false;
            } else { return true; }
        }
    };

    $.fn.inputCheck = function(method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'string' || !method) {
            return methods.checkAllFields.apply(this, arguments);
        }
    };
})(jQuery);