$(document)
    .on('click', '.count-inc.active', function () {

        var $inp = $(this).siblings('.count-val').first();
        var val = +$inp.val() + 1;

        changeCountBtn($inp, val);

        var commonVal = $inp.val();
        var commonPrice = $(this).closest('.cart-item').find('.price-count').text();

        //change item quantity and price for both baskets
        var commonId = $(this).closest('.cart-counter').find('input[name=basket_id]').val();

        $('input[name=basket_id][value=' + commonId + ']').each(function () {
            $(this).siblings('.count-dec').addClass('active');
            $(this).siblings('.count-val').first().val(commonVal);
            $(this).closest('.cart-item').find('.price-count').text(commonPrice);

            if (val >= +$inp.data('value-max')) {
                $(this).siblings('.count-inc').removeClass('active');
            } else if (val == (+$inp.data('value-min') + 1)) {
                $(this).siblings('.count-dec').addClass('active');
            }

        })

        // if (val >= +$inp.data('value-max')) {
        //     $(this).removeClass('active');
        // } else if (val == (+$inp.data('value-min') + 1)) {
        //     $(this).siblings('.count-dec').addClass('active');
        // }

        if ($(this).data('send-func')) {
            window[$(this).data('send-func')](val, $inp);
        }

        //change cartTotalSum
        var cartSum = $('#cart-right .cart-total-sum').data('price');
        var $item = $inp.closest('.cart-item');
        var itemPrice = +$item.find('.one-price').first().val();
        cartSum = +cartSum + itemPrice;
        $('.cart-total-sum').text(cartSum.toLocaleString('ru-RU'));
        $('#cart-right .cart-total-sum').data('price', cartSum);

        //change cart quantity
        var cartCount = +$('.cart-count').text() + 1
        $('.cart-count').text(cartCount)


    })
    .on('click', '.count-dec.active', function () {

        var $inp = $(this).siblings('.count-val').first();
        var val = +$inp.val() - 1;

        changeCountBtn($inp, val);
        var commonVal = $inp.val();
        var commonPrice = $(this).closest('.cart-item').find('.price-count').text();

        //change item quantity and price for both baskets
        var commonId = $(this).closest('.cart-counter').find('input[name=basket_id]').val();

        $('input[name=basket_id][value=' + commonId + ']').each(function () {
            $(this).siblings('.count-inc').addClass('active');
            $(this).siblings('.count-val').first().val(commonVal);
            $(this).closest('.cart-item').find('.price-count').text(commonPrice);

            if (val <= +$inp.data('value-min')) {
                $(this).siblings('.count-dec').removeClass('active');
            } else if (val == (+$inp.data('value-max') - 1)) {
                $(this).siblings('.count-dec').addClass('active');
            }

        })

        // if (val <= +$inp.data('value-min')) {
        //     $(this).removeClass('active');
        // } else if (val == (+$inp.data('value-max') - 1)) {
        //     $(this).siblings('.count-inc').addClass('active');
        // }

        if ($(this).data('send-func')) {
            window[$(this).data('send-func')](val, $inp);
        }

        //change cartTotalSum
        var cartSum = $('#cart-right .cart-total-sum').data('price');
        var $item = $inp.closest('.cart-item');
        var itemPrice = +$item.find('.one-price').first().val();
        cartSum = +cartSum - itemPrice;
        $('.cart-total-sum').text(cartSum.toLocaleString('ru-RU'));

        //change cart quantity
        var cartCount = +$('.cart-count').text() - 1
        $('.cart-count').text(cartCount);
        $('#cart-right .cart-total-sum').data('price', cartSum);

        //remove error
        $(this).siblings('.inp-err').removeClass('active').text('')

    })
    .on('input', '.count-val', function () {
        var $inp = $(this);

        $inp.parent().inputCheck('ru', function (check) {
            var $item = $inp.closest('.cart-item'),
                $dec = $inp.siblings('.count-dec'),
                $inc = $inp.siblings('.count-inc');

            var onePrice = $item.find('.one-price').first().val(),
                $price = $item.find('.price-count');

            if (check) {
                var val = $inp.val();

                if (val > +$inp.data('value-min')) {
                    $dec.addClass('active');
                } else {
                    $dec.removeClass('active');
                }

                if (val < +$inp.data('value-max')) {
                    $inc.addClass('active');
                } else {
                    $inc.removeClass('active');
                }

                $price.text(onePrice * val);
            } else {
                $inp.val(1);
                $dec.removeClass('active');
                $price.text(onePrice);
            }
        });
    })
    .on('click', '.cart-remove', function () {
        $('.confirm').fadeIn();
        $('body').scrollTop(0)
        var _this = this
        btnOkfunction = function () {
            var $cartRemove = $(_this);
            var id = $(_this).closest('.cart-item').find('input[name="basket_id"]').val();
            var data = new FormData();
            data.append("basket_id", id);
            $.ajax({
                type: "POST",
                url: '/api/del-basket/',
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {


                    var response = $.parseJSON(response);

                    if (response.status == 'done') {
                        console.log(response.products);
                        dataLayer.push({
                            'event': 'removeFromCart',
                            'ecommerce': {
                                'currencyCode': $('.price-sym').first().text(),
                                'remove': {                               // 'remove' actionFieldObject measures.
                                    'products': response.products
                                }
                            }
                        });

                        // remove items from both carts
                        $('input[name=basket_id][value="' + id + '"]').each(function () {
                            $(this).closest('.cart-item').remove();
                        });

                        // remove unnecessary blocks when cart is empty
                        if (!$('.cart-content-popup, .cart-dropdown-inner').children().length) {
                            $('.cart-dropdown').removeClass('active').addClass('hide');

                            if ($('html').attr('lang') == 'ru') {
                                $('.cart-title').text('Корзина пуста');
                            } else if ($('html').attr('lang') == 'en') {
                                $('.cart-title').text('Your Bag is empty ');
                            }

                            $('.cart-footer, .cart-show-more, .cart-total').removeClass('active').addClass('hide');
                        }
                        var i = $('.cart-dropdown-inner').find('.cart-item').length;
                        $(".cart-dropdown-inner > .cart-item").hide().slice(i - 2, i).show();

                        if ($('.cart-dropdown-inner').children().length <= 2) {
                            $('.cart-show-more').removeClass('active').addClass('hide');
                        }

                        //change cart count when deleting
                        var initialCartCount = $('.cart-count').text();
                        var countVal = $cartRemove.closest('.cart-item').find('.count-val').val();
                        var updatedCartCount = +initialCartCount - +countVal;
                        $('.cart-count').text(updatedCartCount);
                        $('.cart-number').text($('.cart-dropdown-inner').children().length);

                        //change cartSum
                        var cartSum = $('#cart-right .cart-total-sum').data('price');
                        var $item = $cartRemove.closest('.cart-item');
                        var itemSum = $item.find('.price-count').data('price');
                        cartSum = +cartSum - +itemSum;
                        console.log(cartSum, response.data)
                        $('.cart-total-sum').data('price', response.data).text(response.data.toLocaleString('ru-RU'));

                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }
    })
    .on('click', '.count-inc:not(.active)', function (e) {

        if ($('html').attr('lang') == 'ru') {
            $(this).siblings('.inp-err').addClass('active').text('Такого количества нет в наличии');
        } else if ($('html').attr('lang') == 'en') {
            $(this).siblings('.inp-err').addClass('active').text('Not available');
        }
        setTimeout(function () {
            $('.inp-err').removeClass('active').text('');
        }, 2000);

    })

function changeCountBtn($inp, val) {
    var $item = $inp.closest('.cart-item'),
        price = $item.find('.one-price').first().val() * val;

    $inp.val(val);
    $item.find('.price-count').text(price.toLocaleString('ru-RU')).data('price', price);

    return false;
}

$('.cart-counter input[type="text"]').focus(function () {
    $(this).inputCheck('errRemove');
});


function itemQuantityChange(val, $inp) {

    var quantity = val;
    var id = $inp.siblings('input[name="basket_id"]').val();

    var data = new FormData();
    data.append("basket_id", id);
    data.append("value", quantity);

    $.ajax({
        type: "POST",
        url: '/api/change-quantity/',
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        success: function (response) {
            var response = $.parseJSON(response);

            if (response.status == 'done') {

            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    });
}


$('#product-size input[name="size"]').click(function () {
    $('.cart-btn, .cart-set-btn').removeClass('hide_btn');
    if ($(this).is(':checked') && $('.help-block').hasClass('help-block-error')) {
        addToCart()
    }

});

var TimeCard;
function addToCart() {
    dataLayer.push({'event': 'add_to_cart'});
    var elem = $('.cart-btn, .cart-set-btn').find('span');
    elem.each(function () {
        $(this).data('text', $(this).text())
    });
    var textTrue = true;
    var timeDot = setInterval(function () {
        if (textTrue) {
            elem.text('.');
        }
        setTimeout(function () {
            if (textTrue) {
                elem.text('. .')
            }
            setTimeout(function () {
                if (textTrue) {
                    elem.text('. . .')
                }
            }, 200)
        }, 200)

    }, 600);

    clearTimeout(TimeCard);
    var data = new FormData();
    var $size = $('input[name="size"]:checked');

    var warehouseId = $size.siblings("input[name='warehouse_id']").val();
    var productId = $size.siblings("input[name='product_id']").val();
    var type = $size.siblings("input[name='type']").val();
    var valut = $(".product-about").find("input[name='valut']").val().trim();
    var price = $(".small-w .catalog-price").data('price');

    data.append('warehouse_id', warehouseId);
    data.append('product_id', productId);
    data.append('type', type);
    data.append('valut', valut);
    data.append('price', price);


    $.ajax({
        type: "POST",
        url: '/api/add-to-basket/',
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        success: function (response) {
            var response = $.parseJSON(response);
            textTrue = false
            clearTimeout(timeDot);
            $('#mob-cart-size').fadeOut();

            elem.each(function () {
                $(this).text($(this).data('text'))
            });

            if (response.status == 'done') {
                $('.cart-btn,.cart-set-btn').addClass('hide_btn');
                if (innerWidth < 1200) {
                    $('#mob-cart-done').stop().fadeIn(function () {
                        // setTimeout(function () {
                        //     $('#mob-cart-done').stop().fadeOut()
                        // }, 4000)
                    });
                }
                $('.help-block').removeClass('help-block-error');
                basketUpdate(response.data);
                addTagProduct()

                $('.count-val').each(function () {

                    var val = $(this).val();

                    if (val >= +$(this).data('value-max')) {
                        $(this).siblings('.count-inc').removeClass('active');
                    } else {
                        $(this).siblings('.count-inc').addClass('active');
                    }

                    if (val <= +$(this).data('value-min')) {
                        $(this).siblings('.count-dec').removeClass('active');
                    } else {
                        $(this).siblings('.count-dec').addClass('active');
                    }
                })

                // fix for  open cart-popup, but not need-help popup
                $('.v-dropdown').not('.need-help-btn').addClass('open');

                if ($('.cart-dropdown-inner').find('.cart-item').length > 0) {
                    var i = $('.cart-dropdown-inner').find('.cart-item').length;
                    $(".cart-dropdown-inner > .cart-item").hide().slice(i - 2, i).show()

                }
                $(".cart-item:last-child").addClass('change');
                clearTimeout(TimeCard);
                setTimeout(function () {
                    clearTimeout(TimeCard);
                    $(".cart-item:last-child").removeClass('change');
                    TimeCard = setTimeout(function () {
                        $('.v-dropdown').removeClass('open');
                    }, 1500)
                }, 500)
            } else if (response.status == 'error') {

                var productId = $size.siblings('input[name="product_id"]').val();
                var sizeVal = $size.siblings('label').text().trim();

                $('.help-block').addClass('help-block-error').text(response.error.error);

                //error if item is out of stock
                $('.cart-item input[name="product_id"][value="' + productId + '"]').each(function () {
                    var $parent = $(this).closest('.cart-item');

                    if (sizeVal == $(this).siblings('.size-val').text().trim()) {
                        // $('.help-block').removeClass('help-block-error').text('');
                        $parent.find('.inp-err').addClass('active').text(response.error.error)

                        setTimeout(function () {
                            $parent.find('.inp-err').removeClass('active').text('');
                        }, 2000);
                    }
                })

                // error if size not checked
                if (!$size.length) {
                    $('.help-block').addClass('help-block-error').text(response.error.error);
                }
            }

        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    });
}

function outOfStock($el, data) {

    var $size = $el.find('input[name^="size"]:checked');
    var productId = $size.siblings('input[name="product_id"]').val();
    var sizeVal = $size.siblings('label').text().trim();

    //error if item is out of stock

    $('.cart-item input[name="product_id"][value="' + productId + '"]').each(function () {

        var $parent = $(this).closest('.cart-item');

        if (sizeVal == $(this).siblings('.size-val').text().trim()) {

            $parent.find('.inp-err').addClass('active').text(data.error)

            setTimeout(function () {
                $parent.find('.inp-err').removeClass('active').text('');
            }, 2000);
        }
    })
    // // error if size not checked
    // if (!$size.length){
    //     $('.help-block').addClass('help-block-error').text(data.error);
    // }
}
var productSizeTop = 0
if ($("#product-size").length) {
    productSizeTop = $("#product-size").offset().top - 60
}

$('.cart-btn').on('click', function (e) {
    e.preventDefault();

    var $size = $('.right-product-about input[name="size"]:checked');

    if ($size.length) {

        addToCart($(this));


    } else {

        $('.help-block').addClass('help-block-error');

        $('.checkbox-icon label').addClass('active');
        setTimeout(function () {
            $('.checkbox-icon label.active').removeClass('active');
        }, 1000);

        //check if the browser width is less than or equal to the large dimension of an iPad
        if ($(window).width() <= 768) {
            $('#mob-cart-size').fadeIn();
        }
    }

})
;


//cart for set of goods

$('.cart-set-btn').on('click', function (e) {
    e.preventDefault();

    var data = new FormData();

    //check if all items in the set of goods are checked
    // if not all
    if ($('.catalog-container input[type="checkbox"]').not(':checked').length > 0) {

        $('.help-block').removeClass('help-block-error');

        $('.catalog-one input[type="checkbox"]:checked').each(function () {

            var error = chekForSelectedSizes($(this));

            var data = new FormData();

            var $setItem = $(this).closest('.catalog-one');

            var warehouseId = $setItem.find("input[name='warehouse_id']").val();

            var productId = $setItem.find("input[name='product_id']").val();
            var type = $setItem.find("input[name='type']").val();
            var valut = $("input[name='valut']").val();
            var price = $setItem.find(".item-price").text().trim();


            data.append('warehouse_id', warehouseId);
            data.append('product_id', productId);
            data.append('type', type);
            data.append('valut', valut);
            data.append('price', price);


            if (!error) {
                $.ajax({
                    type: "POST",
                    url: '/api/add-to-basket/',
                    data: data,
                    processData: false,
                    contentType: false,
                    cache: false,
                    success: function (response) {
                        var response = $.parseJSON(response);

                        if (response.status == 'done') {
                            $('.cart-btn,.cart-set-btn').addClass('hide_btn');
                            $('.help-block').removeClass('help-block-error')
                            basketUpdate(response.data)

                        } else if (response.status == 'error') {

                            $('.help-block').addClass('help-block-error').text(response.error.error);
                            outOfStock($setItem, response.error)

                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr.status);
                        console.log(thrownError);
                    }
                });
            }
        });

        // if all items in the set of goods are checked
    } else {

        // if sizes are selected for all checked items in the set
        if ($('.catalog-one').find('input[name^="size"]:checked').length == $('.catalog-container input[type="checkbox"]:checked').length) {

            //remove error
            $('.size-error').animate({opacity: '0'}, 'slow');

            var sizeArray = [];
            var productType = $('.product-page input[name="product_type"]').val();
            var setId = $('.product-page input[name="set_id"]').val();

            $('.catalog-one input[name^="size"]:checked').each(function () {
                var sizeVal = $(this).siblings('input[name="warehouse_id"]').val();
                sizeArray.push(sizeVal);
            });

            data.append('warehouse_id', sizeArray);
            data.append('product_type', productType);
            data.append('set_id', setId);

            $.ajax({
                type: "POST",
                url: '/api/add-set-to-basket/',
                data: data,
                processData: false,
                contentType: false,
                cache: false,
                success: function (response) {
                    var response = $.parseJSON(response);

                    if (response.status == 'done') {
                        basketUpdate(response.data);
                        $('.cart-btn,.cart-set-btn').addClass('hide_btn');
                        $('.help-block').removeClass('help-block-error')
                        $('.count-val').each(function () {

                            var val = $(this).val();

                            if (val >= +$(this).data('value-max')) {
                                $(this).siblings('.count-inc').removeClass('active');
                            } else {
                                $(this).siblings('.count-inc').addClass('active');
                            }

                            if (val <= +$(this).data('value-min')) {
                                $(this).siblings('.count-dec').removeClass('active');
                            } else {
                                $(this).siblings('.count-dec').addClass('active');
                            }
                        })

                    } else if (response.status == 'error') {
                        $('.help-block').addClass('help-block-error').text(response.error.error)
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });


        } else if ($('.catalog-one').find('input[name^="size"]:checked').length !== $('.catalog-container input[type="checkbox"]:checked').length) {

            $('.catalog-one input[type="checkbox"]:checked').each(function () {
                chekForSelectedSizes($(this))
            })
        }
    }
});

function chekForSelectedSizes($el) {

    var error = false

    if (!$el.closest('.catalog-one').find('input[name^="size"]:checked').length) {
        error = true;
        $el.closest('.catalog-one').find('.dropdown-head').css('background', '#e1e9f2');
        setTimeout(function () {
            $('.catalog-one').find('.dropdown-head').each(function () {
                $(this).css("background", '#fff');
            })
        }, 2500);
        $('.help-block').addClass('help-block-error');
    }

    return error
}

function basketUpdate(data) {
    //add items to the cart

    $('.cart-content-popup').html('');
    $('.cart-dropdown-inner').html('');
    $('.mob-cart-done').html('');


    $('#add-items-cart-dropdown').tmpl(data[0]).appendTo('.cart-content-popup');
    var last = data[0].length - 1;
    console.log(data[0][last])


    $('#add-items-cart-popup').tmpl(data[0][last]).appendTo('.mob-cart-done');
    $('#add-items-cart-popup').tmpl(data[0]).appendTo('.cart-dropdown-inner');

    //show only 2 first items
    var i = $('.cart-dropdown-inner').find('.cart-item').length;
    $(".cart-dropdown-inner > .cart-item").hide().slice(i - 2, i).show();

    //add necessary parts when cart is not empty
    $('.cart-dropdown').removeClass('hide').addClass('active');
    if ($('html').attr('lang') == 'ru') {
        $('.cart-title').text('Корзинa');
    } else if ($('html').attr('lang') == 'en') {
        $('.cart-title').text('Your Bag ');
    }
    $('.cart-footer, .cart-total').removeClass('hide').addClass('active');

    if ($('.cart-dropdown-inner').children().length > 2) {
        $('.cart-show-more').removeClass('hide').addClass('active');
    }

    //change cart count when adding
    $('.cart-count').css({
        'overflow': 'hidden',
        'vertical-align': 'middle'
    }).prepend('<div style="margin-top: -20px">' + data[1].count + '</div>').find('div').animate({margin: 0}, function () {
        $('.cart-count').html(data[1].count);
    });

    // $('.cart-count').text(data[1].count);
    $('.cart-number').text($('.cart-dropdown-inner').children().length);

    //update total cart sum and currency
    var cartSum = data[1].sum;
    $('.cart-total-sum').data('price', cartSum).text(cartSum.toLocaleString('ru-RU'));
    var cartValut = data[0][0].currensy;
    $('.price-sym').text(cartValut)


    $('.cart-item').last().find('.count-val').data('value-max', data[1].max_count)


    $('.block-right_content').each(function () {
        var height = $(window).height() - $(this).closest('.block-right').find('.block-right_title').height() - $(this).closest('.block-right').find('.block-right_footer').height() - 72;
        if (innerWidth > 1200) {
            $(this).scrollable({
                height: height,
                width: 280,
                showButtons: false,
                animate: true,
                mousewheelSpeed: 1
            });
        }
        else {
            $(this).height(height).css('overflow-y', 'scroll')

        }
    });
}

$('.btn-checkout-dropdown, .btn-checkout-popup').on('click', function (e) {
    e.preventDefault();

    var error = false;
    var url = this.href;

    $('.cart-wrapper').find('.cart-item').each(function () {

        var $cartItem = $(this);
        var basketId = $cartItem.find('input[name="basket_id"]').val();
        var itemQuantity = $cartItem.find('.count-val').val();

        console.log($cartItem)
        console.log(basketId)
        console.log(itemQuantity)

        var data = new FormData();

        data.append("basket_id", basketId);
        data.append("quantity", itemQuantity);

        $.ajax({
            type: "POST",
            url: '/api/checkout-quantity/',
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            success: function (response) {
                var response = $.parseJSON(response);

                if (response.status == 'done') {

                    error = true;

                } else {
                    $cartItem.find('.inp-err').addClass('active').text(response.error.error)
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(thrownError);
            }
        });

        if (!error) {
            location.href = url;
        }

    })
});


function addToDataLayer(elem) {
    var impressions = []
    elem.each(function () {
        var optiopn = [];

        optiopn.name = $(this).find('.catalog-title span').text();
        optiopn.id = $(this).data('product-id');
        optiopn.price = $(this).data('product-price');
        optiopn.brand = $(this).find('.catalog-category').text();
        optiopn.category = $(this).data('product-category');
        optiopn.list = 'Catalog';
        optiopn.position = $(this).index();
        impressions.push(optiopn);
    });
    dataLayer.push({
        'ecommerce': {
            'currencyCode': $('.price-sym').first().text(),                       // Local currency.
            'impressions': impressions
        }
    });
}
