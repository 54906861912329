(function ($) {
    var PinImage = function ($el) {
        var _this = this;

        this.image = $el;

        var $dots = this.image.find('.image-dot');

        $dots.each(function () {
            $(this).find('.dot-input_src').text(/[a-zA-Z0-9\-\_]*\.[a-zA-Z0-9\-\_\.]*/.exec($(this).find('.dot-input_src').first().text()));

            $(this)
                .css('z-index', $dots.index($(this)) + 1)
                .find('.image-dot_text').css('margin-top', '-' + ($(this).find('.image-dot_text').height() / 2 + 9) + 'px');

            if ($(this).offset().left > (_this.image.offset().left + _this.image.width() - 200)) {
                $(this).addClass('right');
            }

            $(this).hide();
            var $_this = $(this);

            setTimeout(function () {
                $_this.show();
            }, $dots.index($(this)) * 400);

            $(this)
                .on('mouseenter', function () {
                    $(this).siblings('.image-dot').removeClass('animation-open').addClass('animation-close').removeClass('active');
                    $(this).removeClass('animation-close').addClass('animation-open').addClass('active');
                })
                .on('mouseleave', function () {
                    $(this).removeClass('animation-open').addClass('animation-close').removeClass('active');
                });
        });
    };

    $.fn.pinImage = function (methodOrOptions) {
        if (typeof methodOrOptions === 'string') {
            var pinImages = [];

            this.each(function () {
                pinImages.push($(this).data('pinImage'));
            });

            var args = (arguments.length > 1) ? Array.prototype.slice.call(arguments, 1) : undefined;
            var results = [];

            function applyMethod(index) {
                var pinImage = pinImages[index];

                if (!pinImage) {
                    console.warn('$.pinImageCreator not instantiated yet');
                    console.info(this);
                    results.push(undefined);
                    return;
                }

                if (typeof pinImage[methodOrOptions] === 'function') {
                    var result = pinImage[methodOrOptions].apply(pinImage, args);
                    results.push(result);
                } else {
                    console.warn('Method \'' + methodOrOptions + '\' not defined in $.pinImage');
                }
            }

            this.each(applyMethod);

            return (results.length > 1) ? results : results[0];
        } else {
            function init() {
                var $el = $(this);

                $el.data('pinImage', new PinImage($el, methodOrOptions));
            }

            return this.each(init);
        }
    };
})(jQuery);